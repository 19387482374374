import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Park from '../pages/Park';
import QuoteRequest from '../pages/QuoteRequest';
import Contact from '../pages/Contact';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/park" element={<Park />} />
        <Route path="/quote/request" element={<QuoteRequest />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
