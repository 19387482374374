import { t } from 'i18next';
import React from 'react';

interface SelectProps {
  label: string;
  name: string;
  value: string;
  options: { value: string; label: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  className?: string;
  ariaLabel?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  name,
  value,
  options,
  onChange,
  required = false,
  className = '',
  ariaLabel,
}) => {
  return (
    <>
      <label className="block text-gray-700 font-quicksand font-thin">{t(label)} *</label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        aria-label={ariaLabel}
        className={`w-full p-2 border-2 border-primary rounded-xl bg-input focus:outline-none focus:bg-focused-input ${className}`}
      >
        <option value="" disabled>
          {t('Select an option')}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {t(option.label)}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
