import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import logo from '../assets/Logo.png';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  const getLinkClassName = (path: string) => {
    return location.pathname === path
      ? 'text-red-500 border-b-2 border-red-500'
      : 'hover:text-red-500 cursor-pointer';
  };

  return (
    <header className="bg-white shadow-md">
      <div className="flex justify-between items-center p-4 w-2/3 mx-auto">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="OpaleRental Logo" className="h-32" />
          </Link>
        </div>

        <div className="flex items-center space-x-8 font-quicksand font-thin">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt className="text-red-600" />
            <span className="text-gray-700">+33 6 09 79 63 33</span>
          </div>

          <div className="flex items-center space-x-2">
            <FaEnvelope className="text-red-600" />
            <span className="text-gray-700 underline">
              <a href="mailto:contact@opalerental.fr">
                contact@opalerental.fr
              </a>
            </span>
          </div>
        </div>
      </div>

      <nav className="bg-primary text-white">
        <div className="flex justify-between items-center p-4 w-2/3 mx-auto">
          <ul className="flex justify-center space-x-10 py-2 text-lg font-roboto">
            <li className={getLinkClassName('/')}>
              <Link to="/">{t('home')}</Link>
            </li>
            {/*}
            <li className={getLinkClassName('/park')}>
              <Link to="/park">{t('our_park')}</Link>
            </li>
            <li className={getLinkClassName('/quote/request')}>
              <Link to="/quote/request">{t('quote_request')}</Link>
            </li>
            <li className={getLinkClassName('/contact')}>
              <Link to="/contact">{t('contact')}</Link>
            </li>
          */}
          </ul>

          <div className="relative">
            <select
              value={i18n.language}
              onChange={handleLanguageChange}
              className="bg-white text-gray-700 border border-gray-300 rounded-md py-1 px-2 cursor-pointer font-roboto"
            >
              <option value="fr">
                {t('fr')}
              </option>
              <option value="en">
                {t('en')}
              </option>
            </select>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
