import React, { ChangeEvent, FormEvent, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Select from '../components/Select';
import Input from '../components/Input';
import ConsentCheckbox from '../components/ConsentCheckbox';

const QuoteRequest: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('');
  const [formData, setFormData] = useState({
    society: '',
    coordinates: '',
    equipment: '',
    duration: '',
    comment: '',
  });

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    setFormData({ ...formData, equipment: e.target.value });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      console.log(formData);
      alert("Formulaire envoyé !");
  };

  const options = [
    { value: 'Tracteur', label: 'tractor' },
    { value: 'Semi-Remorque', label: 'semi-trailer' },
    { value: 'Porteur', label: 'carrier' },
  ];
  
  return (
    <MainLayout>
      <div className="container mx-auto p-6 w-2/3 mx-auto">
          <h1 className="text-center text-3xl font-roboto font-bold text-secondary mb-6">{t('quote_request_title')}</h1>
          <p className='text-center font-quicksand text-secondary mb-6' dangerouslySetInnerHTML={{ __html: t('quote_request_text') }}></p>
          <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex justify-between">
              <div className="w-[48%]">
                  <Input
                    type="text"
                    label='society'
                    name="society"
                    value={formData.society}
                    onChange={handleChange}
                    required
                    placeholder="placeholder_society"
                  />
              </div>
              <div className="w-[48%]">
                  <Input
                    type="text"
                    label='coordinates'
                    name="coordinates"
                    value={formData.coordinates}
                    onChange={handleChange}
                    required
                    placeholder="placeholder_coordinates"
                  />
              </div>
          </div>
          <div className="flex justify-between">
              <div className="w-[48%]">
                <Select
                  label="equipment"
                  name="equipment"
                  value={selectedValue}
                  options={options}
                  onChange={handleSelectChange}
                  required
                />
              </div>
              <div className="w-[48%]">
                  <Input
                    type="text"
                    label='duration'
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    required
                    placeholder="placeholder_duration"
                  />
              </div>
          </div>
          <div>
              <label className="block text-gray-700 font-quicksand font-thin">{t("comment")}</label>
              <textarea
                  name="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  required
                  placeholder={t('placeholder_comment')}
                  className="w-full p-2 border-2 border-primary rounded-xl bg-input focus:outline-none focus:bg-focused-input h-32 placeholder-white"
              />
          </div>

          <ConsentCheckbox />

          <button
              type="submit"
              className="w-1/3 bg-primary text-white font-roboto font-bold p-2 rounded-lg hover:bg-teal-600 transition duration-200"
          >
              {t('send')}
          </button>
        </form>
      </div>
    </MainLayout>
  );
};

export default QuoteRequest;
