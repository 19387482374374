import React from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Park: React.FC = () => {
  const { i18n } = useTranslation();
  
  return (
    <MainLayout>
      <section className="text-center py-16">
        <h1 className="text-5xl font-bold">{t('test')}</h1>
      </section>
    </MainLayout>
  );
};

export default Park;
