import React, { ChangeEvent, FormEvent, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Input from '../components/Input';
import Button from '../components/Button';

const Contact: React.FC = () => {
  const { i18n } = useTranslation();
  const [formData, setFormData] = useState({
    last_name: '',
    first_name: '',
    email: '',
    object: '',
    message: '',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      console.log(formData);
      alert("Formulaire envoyé !");
  };
  
  return (
    <MainLayout>
      <div className="container mx-auto p-6 w-2/3 mx-auto">
          <h1 className="text-center text-3xl font-roboto font-bold text-secondary mb-6">{t('contact_us')}</h1>
          <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex justify-between">
                  <div className="w-[48%]">
                      <Input
                        type="text"
                        label='last_name'
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                        placeholder="placeholder_last_name"
                      />
                  </div>
                  <div className="w-[48%]">
                      <Input
                        type="text"
                        label='first_name'
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                        placeholder="placeholder_first_name"
                      />
                  </div>
              </div>
              <div>
                  <Input
                    type="email"
                    label='email'
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="placeholder_email"
                  />
              </div>
              <div>
                  <Input
                        type="text"
                        label='object'
                        name="object"
                        value={formData.object}
                        onChange={handleChange}
                        required
                        placeholder="placeholder_object"
                      />
              </div>
              <div>
                  <label className="block text-gray-700 font-quicksand font-thin">{t("message")}</label>
                  <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder={t('placeholder_message')}
                      className="w-full p-2 border-2 border-primary rounded-xl bg-input focus:outline-none focus:bg-focused-input h-32 placeholder-white"
                  />
              </div>
              <button
                  type="submit"
                  className="w-1/3 bg-primary text-white font-roboto font-bold p-2 rounded-lg hover:bg-teal-600 transition duration-200"
              >
                  {t('send')}
              </button>
          </form>
      </div>
    </MainLayout>
  );
};

export default Contact;
